import React, { FC } from 'react'
import { Loading } from '@mindfulchefuk/features/Delivery/components/Loading'
import { Error } from '@mindfulchefuk/features/Delivery/components/Error'
import {
  AccordionItem,
  Box,
  Flex,
  linkStyles,
  Modal,
  ModalHeader,
  PriceText,
  Text,
  theme,
} from '@mindfulchefuk/design-system'
import { ProductCarousel } from '@mindfulchefuk/features/Products/components/ProductCarousel/ProductCarousel'
import styled from '@emotion/styled'
import { useQueryAllProducts, Product } from '@mindfulchefuk/query'
import { ChilliLevel } from '@mindfulchefuk/features/Onboarding/components/RecipeStats/ChilliLevel'
import { ProductModalBanner } from '@mindfulchefuk/features/Products/components/ProductModal/ProductModalBanner'

interface Props {
  product: Product
  onClose: () => void
}

export const ProductModal: FC<Props> = ({ children, onClose, product }) => {
  const { isFetched: loaded, error } = useQueryAllProducts()
  const isDiscounted = !!product.priceBeforeDiscount
  const isSpicy = !!product.spicinessLevel

  if (error) {
    return <Error />
  }

  if (!loaded || !product) {
    return <Loading />
  }

  return (
    <Modal size="large" data-testid="product-modal" closeModal={onClose}>
      <ModalHeader closeModal={onClose}>
        <Text variant="heading-sm" as="h3" color="aubergine">
          {product.name}
        </Text>
      </ModalHeader>
      <Flex
        position="relative"
        color="aubergine"
        flexDirection={['column', 'row']}
      >
        <Box flex={1} minWidth={0}>
          <ProductCarousel images={product.images} />
        </Box>
        <Box flex={1} px={24} pb={[112, 24]} pt={[0, 16]}>
          <Details>
            <Text
              variant="body-md"
              color="aubergine"
              mb={isSpicy ? 14 : 24}
              mt={[24, 0]}
              dangerouslySetInnerHTML={{ __html: product.descriptionHTML }}
            />
            <ChilliLevel spicinessLevel={product.spicinessLevel} />
            <Flex
              display={{ base: 'block', lg: 'flex' }}
              mt={isSpicy ? 10 : 24}
              mb={[28, 8]}
            >
              {isDiscounted && (
                <PriceText
                  price={product.priceBeforeDiscount}
                  color="tomato-550"
                  isDiscounted
                  variant="heading-lg"
                  as="span"
                  mr={14}
                />
              )}
              <PriceText
                price={product.price}
                color={isDiscounted ? 'tomato' : 'aubergine'}
                as="span"
                variant="heading-lg"
              />
            </Flex>
            <ProductModalBanner productId={product.id} />
            {children}
            <Box>
              {product.subDescriptions.map((description, index) => (
                <AccordionItem
                  key={description.title}
                  title={description.title}
                  isFirst={index === 0}
                >
                  <Text
                    variant="body-md"
                    data-testid="product-page-info-copy"
                    key="product-info-content"
                    dangerouslySetInnerHTML={{
                      __html: description.description,
                    }}
                  />
                </AccordionItem>
              ))}
            </Box>
          </Details>
        </Box>
      </Flex>
    </Modal>
  )
}

const Details = styled.section`
  & a {
    color: ${theme.colors.broccoli};

    ${linkStyles}
  }
`
