/* eslint-disable react/no-unknown-property */
import React, { ReactElement } from 'react'
import Link from 'next/link'
import { css } from '@emotion/core'

import routesConfig from '@mindfulchefuk/config/routesConfig'
import { colors } from '@mindfulchefuk/design-system'

const wrapperStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 50vh;
  max-height: 100%;
`

const headingStyles = css`
  color: ${colors.aubergine};
  font-size: 36px;
  font-weight: normal;
  margin-bottom: 5px;
`

const textStyles = css`
  color: ${colors.pepper};
  font-size: 16px;
  margin-bottom: 25px;
`

export const Error = (): ReactElement => (
  <div css={wrapperStyles} data-testid="product-page-error-container">
    <h1 css={headingStyles}>Sorry</h1>
    <p css={textStyles}>Looks like something went wrong on our end.</p>
    <span>
      <Link
        href={{ pathname: routesConfig.upcoming.pathname }}
        as={routesConfig.upcoming.as}
        passHref
      >
        <p css={textStyles}>Back to my Account</p>
      </Link>
    </span>
  </div>
)
