import styled from '@emotion/styled'

import React, { ReactElement } from 'react'

import Loader from '@mindfulchefuk/components/Loader'

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 50vh;
  max-height: 100%;
`

export const Loading = (): ReactElement => (
  <LoaderContainer data-testid="product-page-loading-container">
    <Loader />
  </LoaderContainer>
)
