import React, { ReactElement, useState } from 'react'
import styled from '@emotion/styled'
import { motion, useMotionValue, useTransform } from 'framer-motion'
import { SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'

import { Spinner } from '@mindfulchefuk/components/Loader'
import {
  Box,
  Carousel,
  CarouselProvider,
  Image,
  minWidths,
  SpaceToken,
  Stack,
  theme,
} from '@mindfulchefuk/design-system'
import { Thumbnails } from './Thumbnails'

const CarouselContainer = styled(Box)`
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
`

const LoadingSpinnerContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ActiveSlideIndicator = styled(motion.div)<{
  transitionDurationInMilliseconds: number
}>`
  background-color: ${theme.colors.broccoli};
  height: 5px;
  position: relative;
  transition-duration: ${({ transitionDurationInMilliseconds }) =>
    transitionDurationInMilliseconds}ms;
  transition-property: transform;
`

type APIProductImage = {
  id: string
  url: string
  primary: boolean
}

export type ProductCarouselProps = {
  images: APIProductImage[]
}

export function ProductCarousel({
  images,
}: ProductCarouselProps): ReactElement {
  const TRANSITION_SPEED = 400
  const GRID_SPACING: SpaceToken = 8
  const carouselEnabled = images.length > 1
  const hasOnlyOneImage = images.length === 1
  const hasNoImages = images.length === 0

  const carouselProgress = useMotionValue(0)
  const translateX = useTransform(
    carouselProgress,
    [0, 1],
    [
      `calc(0% + 0px)`,
      `calc(${images.length - 1}00% + ${GRID_SPACING * (images.length - 1)}px)`,
    ]
  )

  const [isDragging, setIsDragging] = useState(false)

  const imageSlides = images.map(({ id, url }) => (
    <SwiperSlide key={id}>
      <Image src={url} />
    </SwiperSlide>
  ))

  return (
    <CarouselProvider>
      <Stack
        data-testid={productCarouselTestIds.container}
        spacing={GRID_SPACING}
        pb={[0, carouselEnabled ? 24 : 0]}
      >
        <CarouselContainer position="relative" display="block">
          {carouselEnabled && (
            <Carousel
              showButtonsOnIndexViewed
              slides={imageSlides}
              resistanceRatio={0}
              onTouchStart={() => {
                setIsDragging(true)
              }}
              onTouchEnd={() => {
                setIsDragging(false)
              }}
              onProgress={(_swiperInstance: SwiperCore, progress: number) => {
                carouselProgress.set(progress)
              }}
              slidesPerView={1}
              testIds={{
                previousSlideButton: productCarouselTestIds.previousSlideButton,
                nextSlideButton: productCarouselTestIds.nextSlideButton,
              }}
            />
          )}
          {hasOnlyOneImage && (
            <Image
              loading="lazy"
              src={images[0].url}
              alt=""
              width={500}
              height={500}
              sources={[
                {
                  media: minWidths.md,
                  width: 269,
                  height: 269,
                },
              ]}
            />
          )}
          {hasNoImages && (
            <LoadingSpinnerContainer>
              <Spinner />
            </LoadingSpinnerContainer>
          )}
        </CarouselContainer>

        {carouselEnabled && (
          <>
            <Thumbnails gridSpacing={GRID_SPACING} images={images} />

            <ActiveSlideIndicator
              data-testid={productCarouselTestIds.activeIndicator}
              transitionDurationInMilliseconds={
                isDragging ? 0 : TRANSITION_SPEED
              }
              style={{
                translateX,
                width: `calc(${100 / images.length}% - ${
                  GRID_SPACING - GRID_SPACING / images.length
                }px)`,
              }}
            />
          </>
        )}
      </Stack>
    </CarouselProvider>
  )
}

export const productCarouselTestIds = {
  container: 'product-carousel-container',
  previousSlideButton: 'product-carousel-previous-slide-button',
  nextSlideButton: 'product-carousel-next-slide-button',
  thumbnail: 'product-carousel-thumbnail',
  activeIndicator: 'product-carousel-active-indicator',
}
