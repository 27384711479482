import React from 'react'
import { useCMSGlobalContent } from '@mindfulchefuk/features/CMS/hooks/useCMSGlobalContent'
import { instrumentProductDetailsBannerClicked } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentBanners'
import { CMSModalBanner } from '@mindfulchefuk/features/CMS/components/CMSModalBanner'

export const ProductModalBanner = ({ productId }: { productId: string }) => {
  const { data } = useCMSGlobalContent()
  if (!data) return null

  const banner = data.productModalBanners?.find((modalBanner) =>
    modalBanner.productIds.includes(productId)
  )

  if (!banner) return null

  return (
    <CMSModalBanner
      redirectURL={banner.url}
      clickHandler={() => {
        instrumentProductDetailsBannerClicked(banner.analyticsRef)
      }}
      testId="cms-product-partnership-banner"
      margin={{ mb: 16 }}
      responsiveImage={banner.image}
      altText={banner.image.alternativeText}
      imgTestId="cms-product-partnership-banner-image"
    />
  )
}
