import React, { ReactElement } from 'react'
import styled from '@emotion/styled'

import {
  Stack,
  Box,
  Image,
  SpaceToken,
  useCarouselContext,
} from '@mindfulchefuk/design-system'
import { APIProductImage } from '@mindfulchefuk/query'

const SlideThumbnail = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  display: block;
  padding-bottom: 100%;
  height: 0;
  background: none;
`

type ThumbnailProps = {
  gridSpacing: SpaceToken
  images: APIProductImage[]
}

export const Thumbnails = ({
  images,
  gridSpacing,
}: ThumbnailProps): ReactElement => {
  const { swiperInstance } = useCarouselContext()
  return (
    <Stack spacing={gridSpacing} direction="row">
      {images.map(({ id, url }, index) => (
        <Box key={id} flex={1}>
          <SlideThumbnail
            data-testid="product-carousel-thumbnail"
            onClick={() => {
              swiperInstance.slideTo(index)
            }}
          >
            <Image loading="lazy" src={url} />
          </SlideThumbnail>
        </Box>
      ))}
    </Stack>
  )
}
